<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0 text-dark">Renew Membership</h1>
        </div><!-- /.col -->
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item">Renew Membership</li>
          </ol>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
    <div class="row rm-p-m" v-if="loading" style="position: absolute; left:15%; right:0; top:50%;">
      <div class="col-md-12 text-center p-3">
        <i class="fa fa-circle-notch fa-spin fa-2x"></i>
      </div>
    </div>
    <div class="row rm-p-m" v-else>
      <div class="col-md-12">
        <div class="clubrenewal">
          <div class="tabbable">
            <div class="h-steps ">
              <section>
                <ul class="header-navigation">
                  <li class="d-none"><span :class="wiz_step === 1 ? 'header-steps current-item' : 'header-steps'">Membership</span></li>
                  <li><span :class="wiz_step === 2 ? 'header-steps current-item' : 'header-steps'">Verify</span></li>
                  <li><span v-if="ncsaEnable" :class="wiz_step === 3 ? 'header-steps current-item' : 'header-steps'">Recruiting</span></li>
                  <li><span :class="wiz_step === 4 ? 'header-steps current-item' : 'header-steps'">Players</span></li>
                  <li><span :class="wiz_step === 5 ? 'header-steps current-item' : 'header-steps'">Donation</span></li>
                  <li><span :class="wiz_step === 6 ? 'header-steps current-item' : 'header-steps'">Legal Agreement</span></li>
                  <li><span :class="wiz_step === 7 ? 'header-steps current-item' : 'header-steps'">Payment</span></li>
                  <li><span :class="wiz_step === 8 ? 'header-steps current-item' : 'header-steps'">Confirmation</span>
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <b-overlay :show="overlayLoader" no-wrap></b-overlay>
        <Membership v-show="wiz_step === 1" @recordMembership="collectData" />
        <Verify v-show="wiz_step === 2" :formData="form" :fields="propFields" @recordVerify="collectData" @recordNCSA="getNCSA" @validateVerifyData="validateVerifyData" @blockRenewal="disableNextNav" @validateTeamConfirmation="validateTeamConfirmation" @recordPlayers="recordPlayers" />
        <NCSA v-show="wiz_step === 3" :formData="membership_benefits" :fields="propFields" @recordNCSA="getNCSA"/>
        <PlayerInformation v-if="wiz_step === 4" :formData="form" :fields="propFields" @updatePlayer="updatePlayer" @playersInvite="playersInvite" @deletePlayer="deletePlayer" />
        <Donation v-show="wiz_step === 5" :formData="form" @recordDonation="collectData" />
        <LegalAgreement v-if="wiz_step === 6" :formData="form" @recordWaiver="collectData" />
        <Payment v-if="wiz_step === 7" :formData="form" @recordPayment="collectData" />
        <Confirmation v-if="wiz_step === 8" :formData="form" />
      </div>
      <div class="col-md-12 text-right mt-2" v-if="wiz_step < 8 && disableNav === false">
        <b-overlay :show="overlayLoader" no-wrap></b-overlay>
        <p class="text-danger" v-if="wiz_step === 2 && this.validateVerifyStep === false">
          Please fill the mandatory fields to proceed further
        </p>
        <div class="btn-group">
          <button v-if="wiz_step > 1" type="button" class="btn btn-outline-primary cancelbtn btn-previious mr-2" @click="wizardPrevStep">Previous Step</button>
          <button v-if="wiz_step < 8" type="button" class="btn btn-secondary btnnextstp btn-next-success" @click="wizardNextStep">Next Step</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Membership from './Membership';
import Verify from "./Verify";
import NCSA from "./NCSA";
import Donation from "./Donation";
import LegalAgreement from "./LegalAgreement";
import Payment from "./Payment";
import { EventBus } from '@/event-bus';
import axios from 'axios';
import Confirmation from "./Confirmation";
import PlayerInformation from "./PlayerInformation";
export default {
  components: {PlayerInformation, Confirmation, Verify, NCSA, Membership, Donation, LegalAgreement, Payment },
  data(){
    return {
      overlayLoader: false,
      validateVerifyStep: true,
      wiz_step: 1,
      propFields: {},
      membership_benefits:null,
      form: {
        removedPlayers: []
      },
      disableNav: false
    };
  },
  methods: {
    collectData(obj){
      Object.assign(this.form, obj);
    },
    getNCSA(obj){
      this.membership_benefits = obj;
      this.form.clubData.membership_benefits = obj;
    },
    validateVerifyData(obj){
      this.validateVerifyStep = obj?.club;
    },
    disableNextNav(value){
      this.disableNav = value;
    },
    removeDonation(value){
      if (value) {
        delete this.form.added_donation_amount;
        delete this.form.donation_amount;
      }
    },
    removeCoach(value){
      this.form.coach_renew = !value;
    },
    validateTeamConfirmation(obj){
      this.validateTeamConfirm = obj;
      if(obj) {
        this.wiz_step++;
        if(this.wiz_step === 3 && !this.ncsaEnable){
          this.wiz_step++;
        }
        window.scrollTo(0, 0);
      }
    },
    recordPlayers(obj){
      this.form.players = obj;
    },
    updatePlayer(obj){
      this.form.players[obj.index] = obj.data;
    },
    playersInvite(obj){
      this.form.invitedPlayers = obj;
    },
    deletePlayer(player){
      if(player?.member_id){
        this.form.removedPlayers.push(player?.member_id);
      }
    },
    wizardNextStep(event){
      EventBus.$emit('updateMembership');
      if(this.wiz_step === 2){
          if(this.validateVerifyStep) {
            EventBus.$emit('confirmTeamSelection');
            return false;
          } else {
            return this.validateVerifyStep;
          }
      }
      if(this.wiz_step === 3) {
        if([undefined, null, ''].includes(this.form.clubData.membership_benefits) && this.propFields?.general?.ncsa?.visible === true && this.propFields?.general?.ncsa?.required === true) {
          EventBus.$emit('validateMembershipBenefits', true);
          return false;
        } else {
          EventBus.$emit('validateMembershipBenefits', false);
        }
      }
      this.errorAgeDivision = false;
      if(this.wiz_step === 5 && this.form?.errors?.donation?.other !== undefined){
        return false;
      }
      if(this.wiz_step === 6) {
        let errors = {
          initial: null,
          agree: []
        };
        if (this.form?.waiver?.initial === undefined || this.form?.waiver?.initial === "") {
          errors.initial = "Please enter initials";
        }
        if (this.form?.waiver?.agree?.[0] !== true) errors.agree[0] = "Please acknowledge by checking the box";
        if (this.form?.waiver?.agree?.[1] !== true) errors.agree[1] = "Please acknowledge by checking the box";

        if (errors.initial !== null || errors.agree.length !== 0) {
          EventBus.$emit('validateWaiver', errors);
          return false;
        }
      }
      if(this.wiz_step === 7){
        EventBus.$emit('validatePayment', this.form);
        if(!this.form?.validatePayment) return false;
        else {
          return this.submitForm();
        }
      }
      this.wiz_step++;
      window.scrollTo(0, 0);
    },
    wizardPrevStep(event){
      this.errorAgeDivision = false;
      this.wiz_step--;
      if(this.wiz_step === 3 && !this.ncsaEnable){
        this.wiz_step--;
      }
    },
    submitForm(){
      this.overlayLoader = true;
      let team = JSON.parse(localStorage.team);
      EventBus.$emit('paymentError', null);
      axios.post(this.basePath + 'api/club/'+team?.club_uid+'/renew', this.form, { headers: this.clubHeaders })
      .then(res => {
        this.overlayLoader = false;
        if(res.data?.data?.success) {
          this.wiz_step++;
          window.scrollTo(0, 0);
          this.form.transactionId = res.data?.data?.response?.transactionId;
        } else {
          EventBus.$emit('paymentError', res.data?.data);
        }
      })
      .catch(err => {
        this.overlayLoader = false;
        console.log(err);
        EventBus.$emit('paymentError', { message: "Transaction Failed. Internal server error" });
      });
    }
  },
  mounted() {
    const retry = setInterval(()=>{
      this.propFields = this.getSiteProps('memberRegistration.team.fields');
      if(this.propFields) clearTimeout(retry);
    }, 100);
    if(this.form.coach_renew === undefined){
      this.removeCoach(false);
    }

    EventBus.$on('removeDonation', this.removeDonation);
    EventBus.$on('removeCoach', this.removeCoach);
  },
  computed: {
    ncsaEnable: function(){
      return this.getSiteProps("general.ncsa_enable");
    },
  }
}
</script>