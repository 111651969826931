<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card-body card">
        <div class="row">
          <div class="col-md-12 register-title">
            <h3 class="mb-3">Membership Types</h3>
            <p class="font-13 text-justify">
              This includes the Team Fee and Head Coach Fee.
            </p>
            <div v-if="memberships.length && clubDataLoaded">
              <ul class="mship-type-fld">
                <template v-for="membership in memberships">
                  <li class="member-type-width mr-2" :key="'membership_key_'+membership.id" :class="{ 'disabled' : membership.blocked }">
                    <input
                        type="radio"
                        :id="'cbmembership_select_'+membership.id"
                        name="form.membership_id"
                        :value="membership.id"
                        v-model="form.membership_id"
                        @change="updateMembershipSelection"
                        :disabled="membership.blocked"
                    >
                    <label :for="'cbmembership_select_'+membership.id">
                      <span class="chk-top-text pb-3">{{ membership.name }}</span>
                      <div class="pb-3">
                        <img src="/svg/alliancefastpitch/user-icon.svg" alt="icon-3">
                      </div>
                      <span class="chk-bot-text pb-2">${{ membership.price }} + ${{ membership.add_on_price }}</span>
                      <span class="chk-box-text pb-2" v-html="membership.note"></span>
                    </label>
                  </li>
                </template>
              </ul>
            </div>
            <div v-else>
              <i class="fa fa-circle-notch fa-spin fa-2x"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { EventBus } from '@/event-bus';
export default {
  data() {
    return {
      memberships: [],
      clubDataLoaded: false,
      form: {}
    }
  },
  methods: {
    fetchMemberships(){
      axios.get(this.basePath+'api/club/memberships-list/club', { headers: this.clubHeaders })
      .then(response => {
        this.memberships = response.data.data;
        let team = JSON.parse(localStorage.team);
        this.form.membership_id = team.club_membership_id.membership_id;
        this.updateMembershipSelection();
      })
      .catch(error => {
        console.log(error);
      })
    },
    updateMembershipSelection(){
      let selectedMembership = this.memberships.filter(item => item.id === this.form.membership_id);
      if(selectedMembership.length) this.form.membership = selectedMembership.shift();
      this.$emit("recordMembership", this.form);
    }
  },
  mounted() {
     this.fetchMemberships();

     EventBus.$on("clubDataLoaded", (value) => {
       this.clubDataLoaded = value;
     });
  }
}
</script>