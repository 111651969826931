<template>
  <div class="verify-view">
    <div class="row" v-if="clubInfo.renewal_disable">
      <div class="col-md-12">
        <div class="alert alert-secondary">
          Sorry! you cannot proceed. <b>Renewal is blocked</b>
        </div>
      </div>
    </div>
    <template v-if="dataLoading === false">
      <div class="row">
        <div class="col-md-12">
          <div class="card-body card">
            <div class="row">
              <div class="col-md-12">
                <h3>Membership</h3>
                <span class="Individual" v-if="this.formData.membership" :key="this.membershipUpdateKey">{{ this.formData.membership.name }} Membership</span>
                <div class="form-check d-none" v-if="clubInfo.team_head_coach && clubInfo.renewFees && clubInfo.renewFees.coachFee">
                  <input type="checkbox" class="form-check-input" id="coachRenewal" :checked="formData.coach_renew" @change="updateCoachRenewalOption">
                  <label class="form-check-label" for="coachRenewal">Head Coach Renewal</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row user-details">
        <div class="col-md-12">
          <div class="card-body card">
            <div class="row">
              <div class="col-md-12 text-right">
                <div class="modal-btn">
                  <button type="button" class="btn btn-secondary" v-b-modal.editclubInfo>
                    <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Edit
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-9">
                    <h3>{{ fields.club_info.tab_title }}</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">{{ fields.club_info.clubname.label }}</label>
                      <span class="title">{{ clubInfo.club_name || 'N/A' }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">{{ fields.club_info.clubMembership.label }}#</label>
                      <span class="title">{{ clubInfo.club_uid }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">Registered Date</label>
                      <span class="title">{{ startDate }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">Expiration Date</label>
                      <span class="title">{{ validThru }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">Membership Status</label>
                      <span class="text-success" v-if="!isMembershipExpired && clubInfo.registration !== undefined && clubInfo.registration.reg_status === 'Active'"><img class="mr-1" src="/dist/img/profile/ico-status-current.png" alt="current"> Current</span>
                      <span class="text-warning" v-else-if="!isMembershipExpired && clubInfo.registration !== undefined"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png" alt="pending"> {{ clubInfo.registration.reg_status || 'N/A' }}</span>
                      <span class="text-warning" v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png" alt="expired"> Expired</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">{{ fields.club_info.member_league.label }}</label>
                      <span class="title" v-if="clubInfo.memberLeageue">{{ clubInfo.memberLeageue.name || "N/A" }}</span>
                      <span class="title" v-else>N/A</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">{{ fields.club_info.age_division.label }}</label>
                      <span class="title" :class="{ 'text-danger text-bold': [null,'',undefined].includes(clubInfo.ageDivision.name) }" v-if="clubInfo.ageDivision != null">{{ clubInfo.ageDivision.name || "N/A"}}</span>
                      <span class="title text-danger text-bold" v-else>N/A</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">{{ fields.club_info.agl_team.label }}</label>
                      <span class="title">{{ clubInfo.agl_team_id || "N/A"}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-9">
                    <h3>Contact Information </h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="form-group col-md-6 col-md-4">
                        <label class="form-label">Head Coach Name</label>
                        <span class="title" :class="{ 'text-danger text-bold': ['',null,undefined].includes(clubInfo.team_head_coach) }">{{ clubInfo.team_head_coach || "N/A" }}</span>
                      </div>
                      <div class="form-group col-md-6 col-md-4">
                        <label class="form-label">Email</label>
                        <span class="title" :class="{ 'text-danger text-bold': [null,'',undefined].includes(clubInfo.email) }">{{ clubInfo.email || "N/A" }}</span>
                      </div>
                    </div>
                    <hr/>
                  </div>
                  <div class="col-md-12 col-lg-12">
                    <div class="form-group">
                      <label class="form-label">{{ fields.club_info.address1.label }}</label>
                      <span class="title" :class="{ 'text-danger text-bold': [null,'',undefined].includes(clubInfo.address) }">{{ clubInfo.address || "N/A" }}</span>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-12">
                    <div class="form-group">
                      <label class="form-label">{{ fields.club_info.address2.label }}</label>
                      <span class="title">{{ clubInfo.address2 || "N/A" }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">{{ fields.club_info.city.label }}</label>
                      <span class="title" :class="{ 'text-danger text-bold': [null,'',undefined].includes(clubInfo.city) }">{{ clubInfo.city || "N/A"  }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">{{ fields.club_info.country.label }}</label>
                      <span class="title" :class="{ 'text-danger text-bold': [null,'',undefined].includes(clubInfo.country) }">{{ clubInfo.country || "N/A" }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">{{ fields.club_info.state_code.label }}</label>
                      <span class="title" :class="{ 'text-danger text-bold': [null,'',undefined].includes(clubInfo.state_code) }">{{ clubInfo.state_code || "N/A" }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">{{ fields.club_info.zip.label }}</label>
                      <span class="title" :class="{ 'text-danger text-bold': [null,'',undefined].includes(clubInfo.zip) }">{{ clubInfo.zip || "N/A" }}</span>
                    </div>
                  </div>
                  <div class="col-md-12"><hr></div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Country code with Mobile</label>
                      <span :class="{ 'text-danger text-bold': [null,'',undefined].includes(clubInfo.phone) }" class="title">{{ clubInfo.phone || "N/A" }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Country code with Home Phone</label>
                      <span class="title">{{ clubInfo.phone_2 || "N/A" }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="text-center m-3">
        <i class="fa fa-circle-notch fa-2x fa-spin"></i>
      </div>
    </template>
    <b-modal id="editclubInfo" size="xl" ok-title="Update"  @show="resetModal" @ok="updateClubInfo" centered>
      <div class="form-body">
        <div class="row" :key="formUpdateKey">
          <div class="col-md-6">
            <div class="row verify-view mb-3">
              <h3>Team Information</h3>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-label" for="clubName">Team Name <sup class="text-danger">*</sup></label>
                  <input type="text" id="clubName" v-model="form.club_name" class="form-control" readonly />
                  <span class="text-sm text-danger">{{ errors.club_name }}</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-label" for="agl_team_id">AGL Team # (optional)</label>
                  <input type="text" id="agl_team_id" v-model="form.agl_team_id" class="form-control" />
                  <span class="text-sm text-danger">{{ errors.agl_team_id }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" v-if="fields.club_info.member_league">
                <div class="form-group">
                  <label for="member_league" class="form-label">{{ fields.club_info.member_league.label }} <sup class="text-danger" v-if="fields.club_info.member_league.required">*</sup></label>
                  <input type="text" class="form-control" maxlength="100" id="member_league" v-model="form.member_league_name" :key="league_name_key" v-bind:readonly="true" name="form.member_league">
                  <small class="text-muted">Member League is depends on state. If you change the state then it will be auto changed</small>
                  <span class="text-sm text-danger">{{ errors.member_league_name }}</span>
                </div>
              </div>
              <div class="col-md-12" v-if="fields.club_info.age_division">
                <div class="form-group">
                  <label class="form-label">{{ fields.club_info.age_division.label }} <sup class="text-danger" v-if="fields.club_info.age_division.required">*</sup></label>
                  <select id="age_division" v-model="form.age_division" class="form-control">
                      <option value=""> Select </option>
                      <option v-for="(ageDivision,i) in ageDivisionList" :key="ageDivision" :value="i">{{ ageDivision }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.age_division }}</span>
                </div>
              </div>
            </div>
            <div class="row verify-view mb-3">
              <h3>Head Coach</h3>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-label" for="clubName"> Member ID <sup class="text-danger">*</sup></label>
                  <div class="form-group input-group input-group">
                    <input type="text" class="form-control" placeholder="Enter your member id" maxlength="45" id="member_id" v-model="form.member_id" name="form.member_id" value="">
                    <span class="input-group-append">
                    <button type="button"  @click="memberSearch();" class="btn btn-outline-warning btn-flat"><i class="fas fa-search"></i>{{ button.SearchButton }}</button>
                  </span>
                  </div>
                  <span class="text-sm text-danger">{{ errors.member_id }}</span>
                </div>
                <div class="form-group">
                  <label class="form-label" for="clubName"> Head Coach Name <sup class="text-danger">*</sup></label>
                  <input type="text" id="coachName" v-model="form.team_head_coach" class="form-control" readonly />
                  <span class="text-sm text-danger">{{ errors.coach_name }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row verify-view mb-3">
              <h3>Contact Information</h3>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-label" for="email">Email <sup class="text-danger">*</sup></label>
                  <input type="email" id="email" v-model="form.email" class="form-control" />
                  <span class="text-sm text-danger">{{ errors.email }}</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-label" for="address">Address Line 1 <sup class="text-danger">*</sup></label>
                  <input type="text" id="address" v-model="form.address" class="form-control" />
                  <span class="text-sm text-danger">{{ errors.address }}</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-label" for="address2">Address Line 2</label>
                  <input type="text" id="address2" v-model="form.address2" class="form-control" />
                  <small>&nbsp;</small>
                  <span class="text-sm text-danger">{{ errors.address2 }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label" for="city">City <sup class="text-danger">*</sup></label>
                  <input type="text" id="city" v-model="form.city" class="form-control" />
                  <span class="text-sm text-danger">{{ errors.city }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label" for="state_code">State <sup class="text-danger">*</sup></label>
                  <select id="state_code" v-model="form.state_code" :key="stateRefreshKey" class="form-control" @change="changeLeague">
                    <option :value="null">Select</option>
                    <template v-if="form.country === 'USA' || form.country === ''">
                      <option v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                    </template>
                    <template v-else>
                      <option v-for="listOfState in fields.club_info.state_code.option" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                    </template>
                  </select>
                  <span class="text-sm text-danger">{{ errors.state_code }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="zip" class="form-label">{{ fields.club_info.zip.label }} <sup class="text-danger" v-if="fields.club_info.zip.required">*</sup></label>
                  <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')"  id="zip" v-model="form.zip" name="form.zip">
                  <span class="text-sm text-danger">{{ errors.zip }}</span>
                </div>
              </div>
              <div :class="[fields.club_info.country.colClass]" v-if="fields.club_info.country">
                <div class="form-group">
                  <label for="Country" class="form-label">{{ fields.club_info.country.label }} <sup class="text-danger" v-if="fields.club_info.country.required">*</sup></label>
                  <select class="form-control" id="country" v-model="form.country" name="form.country" @change="removeStateCode">
                    <option value="">Select</option>
                    <option :value="listOfCountry.country" v-for="listOfCountry in fields.club_info.country.options" :key="listOfCountry.country">{{ listOfCountry.country }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.country }}</span>
                </div>
              </div>
              <div class="col-md-6" v-if="fields.contact.homephone">
                <div class="form-group">
                  <div class="phone-nu-wrapper">
                    <label class="form-label">{{ fields.contact.homephone.label }} <sup class="text-danger" v-if="fields.contact.homephone.required">*</sup></label>
                    <vue-tel-input v-model="form.phone" ref="phone" @validate="validateTelinput" v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                    <span class="text-sm text-danger">{{ errors.homephone }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-6" v-if="fields.contact.mobilephone">
                <div class="form-group">
                  <div class="phone-nu-wrapper">
                    <label class="form-label">{{ fields.contact.mobilephone.label }} <sup class="text-danger" v-if="fields.contact.mobilephone.required">*</sup></label>
                    <vue-tel-input v-model="form.phone_2" ref="phone" @validate="validateTelinput2" v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                    <span class="text-sm text-danger">{{ errors.mobilephone }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="team-confirmation" size="md" ok-title="Yes, I Confirm" cancel-title="No" title="Team Confirmation" @ok="confirmTeamSelection">
      <p>Are you confirm to proceed with the selected team ?</p>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import { EventBus } from "../../../event-bus";

export default {
  props: ['formData', 'fields'],
  data(){
    return {
      clubInfo: {},
      address: {},
      currentMembership:'',
      membershipUpdateKey:0,
      formUpdateKey:0,
      startDate: null,
      validThru: null,
      membership_benefits: null,
      isMembershipExpired: false,
      dataLoading: false,
      button:{
        SearchButton: 'Search'
      },
      form: {},
      errors: {},
      ageDivisionList: [],
      leagueList: [],
      league_name_key: 0,
      validTelinput: true,
      stateRefreshKey: 0,
      validTelinput2: true,
      mobileProps: {
        mode: "international",
        defaultCountry: "US",
        inputOptions:{
          autocomplete:"on",
          autofocus:false,
          id:"",
          maxlength:15,
          readonly:false,
          required:false,
          showDialCode:false,
          styleClasses:"",
          type:"tel"
        }
      },
    }
  },
  methods: {
    removeStateCode(){
      this.stateRefreshKey++;
      this.form.state = null;
      this.form.state_code = null;
    },
    updateCoachRenewalOption(event){
      EventBus.$emit('removeCoach', !event.target.checked);
    },
    confirmTeamSelection(){
      this.$emit('validateTeamConfirmation', true);
    },
    validateTelinput(obj){
      this.validTelinput = obj.valid;
      if(obj.valid){
        delete this.errors.homephone;
      }
    },
    validateTelinput2(obj){
      this.validTelinput2 = obj.valid;
      if(obj.valid){
        delete this.errors.mobilephone;
      }
    },
    resetModal() {
      this.formUpdateKey++;
      this.form = Object.assign(this.form, this.clubInfo);
      if (this.clubInfo.member_id==undefined) {
        this.form.member_id = "";
      }
    },
    recordData(){
      let data = {
        agl_team_id: this.form.agl_team_id,
        member_league: this.form.member_league_id,
        age_division: this.form.age_division,
        age_division_name: this.ageDivisionList[this.form.age_division],
        email: this.form.email,
        team_head_coach_member_id: this.form.team_head_coach_member_id,
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        address: this.form.address,
        address2: this.form.address2,
        city: this.form.city,
        state_code: this.form.state_code,
        country: this.form.country,
        zip: this.form.zip,
        phone: this.form.phone,
        phone_2: this.form.phone_2,
        next_season_start_date: this.clubInfo.next_season_start_date,
      };
      this.$emit('recordVerify', {
        clubData: data,
        membership: this.formData.membership,
        membership_id: this.formData.membership_id,
        teamFee: this.clubInfo?.renewFees?.teamFee,
        coachFee: this.clubInfo?.renewFees?.coachFee,
        playerFee: this.clubInfo?.membership?.player_price,
      });
      this.$emit('recordNCSA',this.membership_benefits);
    },
    recordPlayersData(){
      let playerInfo = [];
      if (this.form?.roster?.length) {
        playerInfo = this.form.roster.map(player => {
          let age = moment(this.clubInfo.next_season_start_date).startOf("year").diff(player?.personal_details?.dob,"year",true);
          age = age > 0 ? Math.ceil((Math.floor(age * 1000) / 1000).toFixed(3)) : 0;
          let aged_out = parseInt(this.ageDivisionList[this.form.age_division]) < age;
          return {
            member_id: player?.id,
            confirmation_code: player?.confirmation_code,
            full_name: player?.personal_details?.full_name,
            first_name: player?.personal_details?.first_name,
            middle_name: player?.personal_details?.middle_name,
            last_name: player?.personal_details?.last_name,
            email: player?.contact_details?.email,
            retype_email: player?.contact_details?.email,
            address1: player?.contact_details?.address1,
            address2: player?.contact_details?.address2,
            city: player?.contact_details?.city,
            country: player?.contact_details?.country,
            zip: player?.contact_details?.zip,
            state_code: player?.contact_details?.state_code,
            state: player?.contact_details?.state,
            dob: player?.personal_details?.dob,
            phone_1: player?.contact_details?.phone_1,
            agl_player_id: player?.personal_details?.agl_player_id,
            graduation_year: player?.personal_details?.graduation_year,
            jersey_number: player?.personal_details?.jersey_number,
            primary_position: player?.personal_details?.primary_position,
            secondary_position: player?.personal_details?.secondary_position,
            throws: player?.personal_details?.throws,
            bats: player?.personal_details?.bats,
            college_committed: player?.personal_details?.college_committed,
            college_details: player?.personal_details?.college_details,
            age: age,
            aged_out: aged_out,
            division: this.ageDivisionList[this.form.age_division]
          }
        });
      }
      console.log("playerInfo=====>", playerInfo);
      this.$emit('recordPlayers', playerInfo);
    },
    memberSearch: function () {
      if(this.form.member_id == ""){
        this.errors= {};
        this.errors.member_id = 'Member ID is required for search';
        return false;
      }
      this.button.SearchButton = 'Searching...';
      this.errors= {};
      axios.get(this.basePath+'api/member_lookup?member_id='+this.form.member_id)
          .then((res) => {
            if(res.data.status == 'success') {
              this.button.SearchButton = 'Search';
              this.memberSerchResultStatus = true;
              this.memberSearchResults = res.data.data;
              if(this.memberSearchResults[0].membership.id !=5){
                this.errors.member_id = 'Member is not a coach';
                return false;
              }

              this.form.team_head_coach = this.memberSearchResults[0].full_name;
              this.form.full_name = this.memberSearchResults[0].full_name;
              this.form.first_name = this.memberSearchResults[0].first_name;
              this.form.last_name = this.memberSearchResults[0].last_name;
              this.form.team_head_coach_member_id =this.memberSearchResults[0].confirmation_code;
              this.form.email = this.memberSearchResults[0].email;
              this.form.address = this.memberSearchResults[0].address1;
              this.form.city = this.memberSearchResults[0].city;
              this.form.state_code = this.memberSearchResults[0].state_code;
              this.form.country = this.memberSearchResults[0].country;
              this.form.zip = this.memberSearchResults[0].zip;
              this.form.phone = this.memberSearchResults[0].phone_1;
              this.form.phone_2 = this.memberSearchResults[0].phone_2;
              this.form.coach_expiry_date = this.memberSearchResults[0].valid_thru;

            } else {
              this.button.SearchButton = 'Search';
              this.memberSerchResultStatus = true;
              this.errors.member_id = 'Member is not found or Invalid Member Id';
              return false;
            }
          })
          .catch((error) => {
            console.log('Error:'+ error);
            this.button.SearchButton = 'Search';
            return false;
            // error.response.status Check status code
          }).finally(() => {


      });
    },
    fetchTeamInfo(){
      this.dataLoading = true;
      EventBus.$emit('clubDataLoaded', false);
      axios.get(process.env.VUE_APP_URL+'api/club/info', { headers: this.clubHeaders })
      .then(response => {
        this.dataLoading = false;
        this.clubInfo = response.data.data;
        //check head coach
        this.startDate = moment(this.clubInfo.reg_date).format('MM/DD/YYYY');
        this.validThru = moment(this.clubInfo.expires_at).format('MM/DD/YYYY');
        this.isMembershipExpired = moment(new Date()).isAfter(this.validThru);
        this.form = Object.assign(this.form, this.clubInfo);
        this.form.age_division = this.clubInfo.ageDivision.id;
        this.form.member_league_name = this.clubInfo.memberLeageue.name;
        this.form.member_league_id = this.clubInfo.memberLeageue.id;
        this.form.team_head_coach_member_id = this.clubInfo.team_head_coach_member_id;

        this.form.membership_id = this.clubInfo?.membership?.id;
        this.form.membership = this.clubInfo?.membership;
        this.membership_benefits = this.clubInfo?.membership_benefits;

        this.getDivisions(this.clubInfo?.membership?.id);
        this.getLeagues();
        //Block Renewal
        if(this.clubInfo.renewal_disable){
          this.$emit('blockRenewal', true);
        }
        let coachExpiryDate = moment(this.clubInfo.primary_contact.member.valid_thru);
        if(!coachExpiryDate.isBefore(moment().startOf('day'))){
          EventBus.$emit('removeCoach', true);
        }
        else{
          EventBus.$emit('removeCoach', false);
        }
        //remove head coach renewal if price empty
        if(this.clubInfo?.renewFees?.coachFee === 0) {
          EventBus.$emit('removeCoach', true);
        }

        EventBus.$emit('clubDataLoaded', true);
      })
      .catch(error => {
        console.log(error);
      })
    },
    getDivisions(id)
    {
      if(this.currentMembership!=id){
        if(this.currentMembership!='')
          this.clubInfo.ageDivision.id='';
        this.form.age_division='';
        this.clubInfo.ageDivision.name='';
        this.recordData();
        axios.get(process.env.VUE_APP_URL+"api/renew/"+id+"/getDivisions",{ headers: this.clubHeaders })
            .then(response => {
              this.ageDivisionList = response.data.data;
              if(this.ageDivisionList[this.clubInfo.ageDivision.id] === undefined){
                this.form.age_division='';
                this.clubInfo.ageDivision.name='';
              }
              else{
                this.form.age_division = this.clubInfo.ageDivision.id;
                this.clubInfo.ageDivision.name=this.ageDivisionList[this.clubInfo.ageDivision.id];
              }
              this.recordData();
              this.recordPlayersData();
              this.validateVerifyData();
              this.currentMembership=id;
            })
            .catch(err => {
              console.log(err);
            });
      }
    },
    getLeagues() {
      axios.get(process.env.VUE_APP_URL + "api/league")
      .then(response => {
        this.leagueList = response.data.data;
      })
      .catch(err => {
        console.log(err);
      });
    },
    changeLeague(){
      let state_code = this.form.state_code;
      axios.get(process.env.VUE_APP_URL+'api/renew/league/' + state_code, { headers: this.clubHeaders })
      .then(res => {
        this.form.member_league_name = res.data.data.league.name;
        this.form.member_league_id = res.data.data.league.id;
        this.league_name_key++;
      })
      .catch(err => {
        console.log(err);
      })
    },
    validatePhone(phone, validTelinput, phoneField= ''){
      let phoneText = (phoneField !== '' ? phoneField : "This field");
      if (!phone) {
        return { valid: false, error: phoneText+' is required .' };
      }
      if(validTelinput === false && phone.length){
        return { valid: false, error: 'Enter the Exact phone number.' };
      }
      return { valid: true, error: null };
    },
    validatePhone2(phone, validTelinput2, phoneField= ''){
      let phoneText = (phoneField !== '' ? phoneField : "This field");
      if(validTelinput2 === false && phone != null){
        return { valid: false, error: 'Enter the Exact phone number.' };
      }
      return { valid: true, error: null };
    },
    validateVerifyData(){
      this.clubValid = this.validateClubInfo()<=0;
      this.$emit('validateVerifyData', { club: this.clubValid });
    },
    validateClubInfo(){
      this.errors = {};
      if (!this.form.member_league_name) this.errors.member_league_name = "Member League is required";
      if (!this.form.email) this.errors.email = "Email is required";
      if (!this.form.address) this.errors.address = "Address Line 1 is required";
      if (!this.form.city) this.errors.city = "City is required";
      if (!this.form.state_code) this.errors.state_code = "State is required";
      if(this.form.country){
        if(!this.form.zip){
          this.errors.zip = "Zip/Postal code is required";
        }
        else if(this.form.country == "USA" && !this.form.zip.match(/^\d{5}(-\d{4})?$/)){
          this.errors.zip = "Zip/Postal Code Should Be 5 Number Values for USA";
        }
      }
      else{
        this.errors.country = "Country is required";
      }
      if (!this.form.zip) this.errors.zip = "Postal Code is required";
      if(!this.form.team_head_coach_member_id) this.errors.coach_name = "Head Coach is required";
      if (!this.form.age_division) this.errors.age_division = "Age Division is required";


      const validPhone1 = this.validatePhone(this.form.phone, this.validTelinput);
      if(!validPhone1.valid) this.errors.homephone = validPhone1.error;
      const validPhone2 = this.validatePhone2(this.form.phone_2, this.validTelinput2);
      if(!validPhone2.valid) this.errors.mobilephone = validPhone2.error;
      return Object.keys(this.errors).length;
    },
    updateClubInfo(bvModalEvt){
      bvModalEvt.preventDefault();
      if(this.validateClubInfo()) return false;
      this.clubInfo.agl_team_id = this.form.agl_team_id;
      this.clubInfo.ageDivision.id = this.form.age_division;
      this.clubInfo.ageDivision.name = this.ageDivisionList[this.form.age_division];
      this.clubInfo.email = this.form.email;
      this.clubInfo.address = this.form.address;
      this.clubInfo.address2 = this.form.address2;
      this.clubInfo.city = this.form.city;
      this.clubInfo.state_code = this.form.state_code;
      this.clubInfo.zip = this.form.zip;
      this.clubInfo.country = this.form.country;
      this.clubInfo.phone = this.form.phone;
      this.clubInfo.phone_2 = this.form.phone_2;
      this.clubInfo.member_id =  this.form.member_id;
      if(!['',null,undefined].includes(this.form.full_name)){
        this.clubInfo.team_head_coach = this.form.team_head_coach;

        let coachExpiryDate = moment(this.form.coach_expiry_date);
        if(!coachExpiryDate.isBefore(moment().startOf('day'))){
          EventBus.$emit('removeCoach', true);
        }
        else{
          EventBus.$emit('removeCoach', false);
        }
      }
      this.recordData();
      this.$nextTick(() => {
        this.validateVerifyData();
        this.$bvModal.hide('editclubInfo');
      });
      this.recordPlayersData();
    }
  },
  mounted() {
    this.fetchTeamInfo();
    EventBus.$on('updateMembership', obj => {
      this.errors = {};
      this.membershipUpdateKey++;
      this.getDivisions(this.formData.membership_id);
    });
    this.$emit('validateTeamConfirmation', false);
    EventBus.$on('confirmTeamSelection', () => {
      this.$bvModal.show('team-confirmation');
    });
  },
}
</script>
<style>
.btn-outline-warning {
  color: #05213e;
  border-color: #05213e;
}
.verify-view .form-group label.form-label{
  font-weight: normal !important;
}
</style>