<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card waiver">
        <div class="card-header register-title">
          <h3>RELEASE AND WAIVER OF LIABILITY</h3>
        </div>
        <div class="card-body">
          <form>
            <div class="row" v-if="waivers.length">
              <div class="col-md-12 text-justify">
                <div class="text-center mb-1">
                  <strong>This <span>waiver</span> is for your membership effective from <span>{{ startDate }} to {{ endDate }}</span></strong>
                </div>
                <div class="mb-5 text-justify" v-for="(waiver,index) in waivers" :key="'waiver'+index" v-html="waiver.description"></div>
                <template v-if="subdomain === 'alliancefastpitch'">
                  <div class="d-flex align-items-start mt-3">
                    <input type="checkbox" class="mt-2" v-model="form.waiver.agree[0]" @change="updateWaiverData">
                    <div class="waiverinner d-flex">
                      <input type="text" maxlength="2" class="waivertext" v-model="form.waiver.initial" @change="updateWaiverData">
                      <label class="form-check-label">
                        <p v-html="propFields.coach_acknowledgement_I"></p>
                      </label>
                    </div>
                  </div>
                  <p class="text-danger" v-if="errors.initial">{{ errors.initial }}</p>
                  <p class="text-danger" v-if="errors.agree && errors.agree[0]">{{ errors.agree[0] }}</p>
                  <div class="d-flex align-items-start mt-3">
                    <input type="checkbox" class="mt-2" v-model="form.waiver.agree[1]" @change="updateWaiverData">
                    <div class="waiverinner d-flex">
                      <input type="text" maxlength="2" class="waivertext" v-model="form.waiver.initial" @change="updateWaiverData">
                      <label class="form-check-label">
                        <p v-html="propFields.coach_acknowledgement_II"></p>
                      </label>
                    </div>
                  </div>
                  <p class="text-danger" v-if="errors.initial">{{ errors.initial }}</p>
                  <p class="text-danger" v-if="errors.agree && errors.agree[1]">{{ errors.agree[1] }}</p>
                </template>
              </div>
            </div>
            <div class="m-5 text-center" v-else>
              <i class="fa fa-circle-notch fa-spin fa-2x"></i>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import { EventBus } from '@/event-bus';
export default {
  props: ['formData'],
  data(){
    return {
      waivers: [],
      startDate: null,
      endDate: null,
      form: {
        waiver: {}
      },
      errors: {}
    };
  },
  methods: {
    fetchWaivers(){
      axios.get(
        process.env.VUE_APP_URL+'api/club/'+this.formData.membership_id+'/waivers/'+this.formData.clubData.team_head_coach_member_id,
        { headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('clubToken')
        }}
      )
      .then(response => {
        this.waivers = response.data.data.waivers;
        this.startDate = moment(response.data.data.start, "YYYY-MM-DD").format("MM/DD/YYYY");
        this.endDate = moment(response.data.data.end, "YYYY-MM-DD").format("MM/DD/YYYY");
        this.form.waiver.initial = response.data.data.initial;
      })
      .catch(err => console.log(err))
    },
    updateWaiverData(event){
      this.$emit('recordWaiver', this.form);
    }
  },
  mounted() {
    this.form.waiver = {
      initial: this.formData.waiver && this.formData.waiver.initial ? this.formData.waiver.initial : null,
      agree: this.formData.waiver && this.formData.waiver.agree ? this.formData.waiver.agree : []
    };
    this.fetchWaivers();
    this.updateWaiverData(null);
    EventBus.$on('validateWaiver', obj => {
      this.errors = obj;
    });
  },
  computed: {
    propFields(){
      if(this.formData.membership.member_type_id === 4)
        return this.getSiteProps('memberRegistration.team.fields.waivers');
      else
        return this.getSiteProps('memberRegistration.individual.fields.waivers');
    }
  }
}
</script>