<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header register-title">
          <h3 class="card-title">Player Information</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12"><p>* Player fee (${{ playerFee }}) will be charged for each player (including newly added too)</p></div>
            <div class="col-md-12">
              <div class="btn-group">
                <button class="btn btn-outline-primary" @click="addPlayer()"><i class="fa fa-plus mr-2"></i>Add Player</button>
                <button class="btn btn-outline-primary" v-b-modal.modal-invite><i class="fa fa-envelope mr-2"></i>Invite Members <span class="badge badge-dark ml-2" v-if="inviteForm.invite_players && inviteForm.invite_players.length > 0">{{ inviteForm.invite_players.length }}</span></button>
              </div>
              <div v-if="ageOut && updateAgeOut" class="alert alert-warning mt-2 mb-0">
                <p><b>Some players are aged out.</b> Those will be excluded from the payment processing and after payment successful those players are turned as free agents.</p>
                <p class="mb-0">If you really want those players in this renewal, please go back and upgrade the age division</p>
              </div>
            </div>
          </div>
          <div class="row mt-3 family-members" :key="updateIndex" v-if="formData.players">
            <div v-if="inviteForm.invite_players && inviteForm.invite_players.length > 0">
              <h3 class="card-title">Invited Members</h3>
              <div class="col-md-12" :key="'player'+index">
                <div class="card clear-fix">
                  <div class="card-header">
                    <template v-for="(player, index) in inviteForm.invite_players">
                      <div :key="'player'+index">
                        <h3 class="card-title mt-2 mr-2">{{ index + 1 }}. {{ player }}</h3>
                        <button class="btn btn-outline-primary btn-sm mt-2" @click="removeInvite(player)"><i
                            class="fa fa-trash mr-2"></i>Remove
                        </button>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <h3 class="card-title" v-if="formData.players && formData.players.length > 0">Players</h3>
            </div>
            <template v-for="(player,index) in formData.players">
              <div class="col-md-12" :key="'player'+index">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title mt-2 mr-2" :class="{'text-danger' : player.aged_out}">{{ index + 1 }}. {{ player.first_name + " " + player.last_name }}</h3>
                    <button class="btn btn-outline-primary btn-sm mt-2 mr-2" @click="editPlayer(player, index)"><i class="fa fa-edit mr-2"></i>Edit</button>
                    <button class="btn btn-outline-primary btn-sm mt-2" @click="deletePlayer(index, player.full_name)"><i class="fa fa-trash mr-2"></i>Remove</button>
                    <button class="btn btn-outline-primary pull-right" v-b-toggle="'player-toggle-' + index"><i class="fa fa-chevron-down fa-lg"></i></button>
                  </div>
                  <b-collapse :id="'player-toggle-' + index" accordion="player-accordion" role="tabpanel">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>Confirmation Code</strong></label>
                            <span class="title">{{ player.confirmation_code || "N/A" }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>Middle Name</strong></label>
                            <span class="title">{{ player.middle_name || "N/A" }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>DOB</strong></label>
                            <span class="title">{{ player.dob || 'N/A' }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>E-Mail</strong></label>
                            <span class="title">{{ player.email || 'N/A' }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>Phone</strong></label>
                            <span class="title">{{ player.phone_1 || 'N/A' }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>AGL Player ID</strong></label>
                            <span class="title">{{ player.agl_player_id || 'N/A' }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>High School Graduation Year</strong></label>
                            <span class="title">{{ player.graduation_year || 'N/A' }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>Jersey Number</strong></label>
                            <span class="title">{{ player.jersey_number || 'N/A' }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>Primary Position</strong></label>
                            <span class="title">{{ player.primary_position || 'N/A' }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>Secondary Position</strong></label>
                            <span class="title">{{ player.secondary_position || 'N/A' }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>Throws</strong></label>
                            <span class="title">{{ player.throws == 1 ? 'Left' : (player.throws == 2 ? 'Right' : 'N/A') }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>Bats</strong></label>
                            <span class="title">{{ player.bats == 1 ? 'Left' : (player.bats == 2 ? 'Right' : 'N/A') }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>College Committed</strong></label>
                            <span class="title">{{ player.college_committed ? player.college_details.name : 'N/A' }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>Mailing Address</strong></label>
                            <span class="title">{{  player.address1 || 'N/A' }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>Address 2</strong></label>
                            <span class="title">{{ player.address2 || 'N/A' }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>City</strong></label>
                            <span class="title">{{ player.city || 'N/A' }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>Country</strong></label>
                            <span class="title">{{ player.country || 'N/A' }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>State</strong></label>
                            <span class="title">{{ player.state_code || 'N/A' }}</span>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="form-label"><strong>Postal code</strong></label>
                            <span class="title">{{ player.zip || 'N/A' }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-collapse>
                </div>
            </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="edit-player" size="lg" ok-only centered :title="editIndex ? 'Edit Player Details' : 'Add Player Details'" ok-title="Save" @ok="updatePlayer">
      <div class="row" v-if="propFields">
        <div class="col-md-5">
          <div class="form-group">
            <label class="form-label" for="form.first_name">{{ propFields.firstName.label }}<sup class="text-danger" v-if="propFields.firstName.required">*</sup></label>
            <input type="text" class="form-control" :placeholder="propFields.firstName.placeholder" maxlength="45" id="form.first_name" v-model="editForm.first_name" name="form.first_name">
            <span class="text-sm text-danger">{{ errors.first_name }}</span>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label" for="form.middleInitial">{{ propFields.middleInitial.label }}<sup class="text-danger" v-if="propFields.middleInitial.required">*</sup></label>
            <input type="text" class="form-control" :placeholder="propFields.middleInitial.placeholder" maxlength="3" id="form.middleInitial" v-model="editForm.middle_name" name="form.middle_name">
            <span class="text-sm text-danger">{{ errors.middle_name }}</span>
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-group">
            <label class="form-label" for="form.last_name">{{ propFields.LastName.label }}<sup class="text-danger" v-if="propFields.LastName.required">*</sup></label>
            <input type="text" class="form-control" :placeholder="propFields.LastName.placeholder" maxlength="45" id="form.last_name" v-model="editForm.last_name" name="form.last_name">
            <span class="text-sm text-danger">{{ errors.last_name }}</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-label" for="form.email">{{ propFields.email.label }}<sup class="text-danger" v-if="propFields.email.required">*</sup></label>
            <input type="email" class="form-control" :placeholder="propFields.email.placeholder" id="form.email" v-model="editForm.email" name="form.email">
            <span class="text-sm text-danger">{{ errors.email }}</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-label" for="form.retype_email">{{ propFields.retype_email.label }}<sup class="text-danger" v-if="propFields.retype_email.required">*</sup></label>
            <input type="email" class="form-control" :placeholder="propFields.retype_email.placeholder" id="form.retype_email" v-model="editForm.retype_email" name="form.retype_email">
            <span class="text-sm text-danger">{{ errors.retype_email }}</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-label">{{ propFields.birthDay.label }}<sup class="text-danger" v-if="propFields.birthDay.required">*</sup></label>
            <div class="input-group birthdate">
              <div class="birthdaypicker">
                <date-pick
                    v-model="editForm.dob"
                    :pickTime="false"
                    name="editForm.dob"
                    :format="'MM/DD/YYYY'"
                    :selectableYearRange="{from: 1900, to: 2021}"
                    v-mask="'##/##/####'"
                    :isDateDisabled="isFutureDate"
                    :inputAttributes="{placeholder: 'mm/dd/yyyy'}"
                    :parseDate="parseDatePick"
                ></date-pick>
              </div>
              <span class="text-sm text-danger">{{ errors.dob }}</span>
            </div>
          </div>
        </div>
        <div :class="[propFields.homephone.colClass]" v-if="propFields.homephone">
          <div class="form-group">
            <div class="phone-nu-wrapper">
              <label class="form-label">{{ propFields.homephone.label }}<sup class="text-danger" v-if="propFields.homephone.required">*</sup><span class="help-text-font-size-11">{{ '('+ propFields.homephone.helpText +')' }}</span></label>
              <vue-tel-input :inputOptions="{ placeholder: 'Phone Number' }" @validate="validateTelinput" ref="phone" v-model="editForm.phone_1" v-bind="homeProps" :validCharactersOnly="true"></vue-tel-input>
              <span class="text-sm text-danger">{{ errors.phone_1 }}</span>
            </div>
          </div>
        </div>
        <div :class="[propFields.address1.colClass]" v-if="propFields.address1">
          <div class="form-group">
            <label for="firstname" class="form-label">{{ propFields.address1.label }} <sup class="text-danger" v-if="propFields.address1.required">*</sup></label>
            <input type="text" class="form-control" maxlength="100" id="address1" v-model="editForm.address1" name="form.address1">
            <span class="text-sm text-danger">{{ errors.address1}}</span>
          </div>
        </div>
        <div :class="[propFields.address2.colClass]" v-if="propFields.address2">
          <div class="form-group">
            <label for="firstname" class="form-label">{{ propFields.address2.label }} <sup class="text-danger" v-if="propFields.address2.required">*</sup></label>
            <input type="text" class="form-control" maxlength="100" id="address2" v-model="editForm.address2" name="form.address1">
            <span class="text-sm text-danger">{{ errors.address2}}</span>
          </div>
        </div>
        <div :class="[propFields.city.colClass]" v-if="propFields.city">
          <div class="form-group">
            <label for="city" class="form-label">{{ propFields.city.label }} <sup class="text-danger" v-if="propFields.city.required">*</sup></label>
            <input type="text" class="form-control" maxlength="100" id="city" v-model="editForm.city" name="form.address1">
            <span class="text-sm text-danger">{{ errors.city}}</span>
          </div>
        </div>
        <div :class="[propFields.country.colClass]"  v-if="propFields.country">
          <div class="form-group">
            <label for="Country" class="form-label">{{ propFields.country.label  }} <sup class="text-danger" v-if="propFields.country.required">*</sup></label>
            <select class="form-control" id="country" @change="removeStateCode" v-model="editForm.country" name="form.country">
              <option value="">Select</option>
              <option :value="listOfCountry.country" v-for="listOfCountry in propFields.country.options" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
            </select>
            <span class="text-sm text-danger">{{ errors.country}}</span>
          </div>
        </div>
        <div :class="[propFields.state_code.colClass]" v-if="editForm.country == 'USA' && propFields.state_code">
          <div class="form-group">
            <label for="state_code" class="form-label">{{ propFields.state_code.label }} <sup class="text-danger" v-if="propFields.state_code.required">*</sup></label>
            <select class="form-control" id="state_code" v-model="editForm.state_code" @change="stateCode" name="form.state_code">
              <option value="">Select</option>
              <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
            </select>
            <span class="text-sm text-danger">{{ errors.state_code}}</span>
          </div>
        </div>
        <div :class="[propFields.state_code.colClass]" v-else>
          <div class="form-group">
            <label for="state_code" class="form-label">{{ propFields.state_code.label }} <sup class="text-danger" v-if="propFields.state_code.required">*</sup></label>
            <select class="form-control" id="state_code" v-model="editForm.state_code" @change="stateCode" name="form.state_code">
              <option value="">Select</option>
              <option  v-for="listOfState in propFields.state_code.option" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
            </select>
            <span class="text-sm text-danger">{{ errors.state_code}}</span>
          </div>
        </div>
        <div :class="[propFields.zip.colClass]" v-if="propFields.zip">
          <div class="form-group">
            <label for="zip" class="form-label">{{ propFields.zip.label }} <sup class="text-danger" v-if="propFields.zip.required">*</sup></label>
            <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')"  id="zip" v-model="editForm.zip" name="form.zip">
            <span class="text-sm text-danger">{{ errors.zip}}</span>
          </div>
        </div>
        <div :class="[propFields.agl_player_id.colClass]" v-if="propFields.agl_player_id">
          <div class="form-group">
            <label for="agl_player_id" class="form-label">
              {{ propFields.agl_player_id.label }}<sup class="text-danger" v-if="propFields.agl_player_id.required">*</sup>
            </label>
            <input type="text" class="form-control" :placeholder="propFields.agl_player_id.placeholder" maxlength="45" id="agl_player_id" v-model="editForm.agl_player_id" name="form.agl_player_id">
            <span class="text-sm text-danger">{{ errors.agl_player_id }}</span>
          </div>
        </div>
        <div :class="[propFields.graduation_year.colClass]" v-if="propFields.graduation_year">
          <div class="form-group">
            <label for="graduation_year" class="form-label">
              {{ propFields.graduation_year.label }}<sup class="text-danger" v-if="propFields.graduation_year.required">*</sup>
            </label>
            <select class="form-select form-control" id="graduation_year" required="" name="form.graduation_year" v-model="editForm.graduation_year">
              <option :value="null">Select Graduation Year</option>
              <option v-for="year in yearArr" :value="year" :key="year">{{ year }}</option>
            </select>
            <span class="text-sm text-danger">{{ errors.graduation_year }}</span>
          </div>
        </div>
        <div :class="[propFields.jersey_number.colClass]" v-if="propFields.jersey_number">
          <div class="form-group">
            <label for="jersey_number" class="form-label">
              {{ propFields.jersey_number.label }}<sup class="text-danger" v-if="propFields.jersey_number.required">*</sup>
            </label>
            <input class="form-control" :placeholder="propFields.jersey_number.placeholder" maxlength="3" id="jersey_number" v-model="editForm.jersey_number" name="form.jersey_number">
            <span class="text-sm text-danger">{{ errors.jersey_number }}</span>
          </div>
        </div>
        <div :class="[propFields.primary_position.colClass]" v-if="propFields.primary_position">
          <div class="form-group">
            <label for="primary_position" class="form-label">{{ propFields.primary_position.label }} <sup class="text-danger" v-if="propFields.primary_position.required">*</sup></label>
            <select class="form-control" id="primary_position" v-model="editForm.primary_position" name="form.primary_position">
              <option :value="null">Select</option>
              <option  v-for="(listOfPrimaryPosition,key) in propFields.primary_position.options" :key="key" :value="listOfPrimaryPosition">{{ listOfPrimaryPosition }}</option>
            </select>
            <span class="text-sm text-danger">{{ errors.primary_position }}</span>
          </div>
        </div>
        <div :class="[propFields.secondary_position.colClass]" v-if="propFields.secondary_position">
          <div class="form-group">
            <label for="secondary_position" class="form-label">{{ propFields.secondary_position.label }} <sup class="text-danger" v-if="propFields.secondary_position.required">*</sup></label>
            <select class="form-control" id="secondary_position" v-model="editForm.secondary_position" name="form.secondary_position">
              <option :value="null">Select</option>
              <option  v-for="(listOfSecondaryPosition,key) in propFields.secondary_position.options" :key="key" :value="listOfSecondaryPosition">{{ listOfSecondaryPosition }}</option>
            </select>
            <span class="text-sm text-danger">{{ errors.secondary_position }}</span>
          </div>
        </div>
        <div :class="[propFields.throw.colClass]">
          <div class="form-group">
            <label class="form-label">{{ propFields.throw.label }} <sup class="text-danger" v-if="propFields.throw.required">*</sup></label>
            <div class="form-group" v-if="propFields.throw.choices">
              <template v-for="(choice, key) in propFields.throw.choices">
                <input type="radio" :id="choice.value" v-model="editForm.throws" name="form.throws" :value="choice.value" :key="'radio_' + key">
                <label class="throw-label-span mr-3" :for="choice.value" :key="'throw_label_'+key">{{ choice.label }}</label>
              </template>
              <p class="text-sm text-danger">{{ errors.throws }}</p>
            </div>
          </div>
        </div>
        <div :class="[propFields.bats.colClass]">
          <div class="form-group">
            <label class="form-label">{{ propFields.bats.label }} <sup class="text-danger" v-if="propFields.bats.required">*</sup></label>
            <div class="form-group" v-if="propFields.bats.choices">
              <template v-for="(choice, key) in propFields.bats.choices">
                <input type="radio" :id="choice.value" v-model="editForm.bats" name="form.bats" :value="choice.value" :key="'radio_' + key">
                <label class="bats-label-span mr-3" :for="choice.value" :key="'bats_label_'+key">{{ choice.label }}</label>
              </template>
              <p class="text-sm text-danger">{{ errors.bats }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="propFields">
        <div :class="[propFields.college_committed.colClass]">
          <div class="form-group">
            <label class="form-label">{{ propFields.college_committed.label }} <sup class="text-danger" v-if="propFields.college_committed.required">*</sup></label>
            <div class="form-group" v-if="propFields.college_committed.choices">
              <template v-for="(choice, key) in propFields.college_committed.choices">
                <input type="radio" :id="choice.value" v-model="collegeCommitted" name="form.college_committed" :value="choice.value" @click="collegeCommitted = choice.value" :key="'radio_' + key">
                <label class="college_committed-label-span mr-3" :for="choice.value" :key="'college_committed_label_'+key">{{ choice.label }}</label>
              </template>
              <span class="text-sm text-danger">{{ errors.college_committed }}</span>
            </div>
          </div>
        </div>
        <div :class="[propFields.college_committed.yes_fields.college_list.colClass]" v-if="propFields.college_committed.yes_fields.college_list && collegeCommitted == 1">
          <div class="form-group">
            <label class="form-label" v-if="propFields.college_committed.yes_fields.college_list.label !==''">{{ propFields.college_committed.yes_fields.college_list.label }} <sup class="text-danger" v-if="propFields.college_committed.yes_fields.college_list.required">*</sup></label>
            <Select2 id="college_list" v-model="editForm.college_committed" :options="collegeOptions" name="form.college_list" :settings="{ placeholder: propFields.college_committed.yes_fields.college_list.placeholder}" @select="updateCollegeCommitted($event)" />
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-invite" ref="modal-invitePlayer" size="lg" ok-title="" hide-footer centered title="Player Details">
      <div class="form-body">
        <form>
          <template>
            <div v-if="propFields.invitation_email">
              <label>Player / Staff E-Mail Address<sup class="text-danger" v-if="propFields.invitation_email.required">*</sup></label>
              <br><em>{{ propFields.invitation_email.helpText }}</em>
              <b-form-tags
                  input-id="invite_user"
                  type="email"
                  v-model="inviteForm.invite_players"
                  placeholder=" "
                  :tag-validator="emailValidator"
                  tagClass="invite_tag"
                  separator=" ,"
                  @tag-state="onTagState"
              ></b-form-tags>
            </div>
          </template>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm btn-alfa" @click="setInvite">
          Send
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";

export default {
  props: ["formData", "fields"],
  data(){
    return {
      updateIndex: 1,
      updateAgeOut: true,
      editIndex: null,
      editForm: {},
      errors: {},
      validTelinput: false,
      yearArr: [],
      homeProps: {
        mode: "international",
        defaultCountry: "US",
        inputOptions:{
          autocomplete:"on",
          autofocus:false,
          id:"",
          maxlength:20,
          name:"telephone",
          placeholder:"Home Number",
          readonly:false,
          required:false,
          showDialCode:false,
          styleClasses:"",
          tabindex:0,
          type:"tel"
        }
      },
      collegeCommitted: null,
      inviteForm:{
        invite:true,
        invite_players:''
      }
    };
  },
  methods: {
    validateTelinput(obj){
      this.validTelinput = obj.valid;
      if(obj.valid){
        this.errors.phone_1 = '';
      }
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    checkAgedOut(dob) {
      let age = moment(this.formData?.clubData?.next_season_start_date).startOf("year").diff(dob,"year", true);
      age = age > 0 ? Math.ceil((Math.floor(age * 1000) / 1000).toFixed(3)) : 0;
      let aged_out = parseInt(this.formData?.clubData?.age_division_name) < age;
      return { age, aged_out };
    },
    validateDOB(dob){
      if (!dob) {
        return "DOB is required";
      } else {
        let agedOut = this.checkAgedOut(dob);
        if(agedOut.aged_out){
          return this.formData?.membership?.name + " player should be age " + parseInt(this.formData?.clubData?.age_division_name) + " and below";
        }
      }
      return false;
    },
    updateCollegeCommitted(event){
      this.editForm.college_details = { id: event.id, name: event.text };
    },
    deletePlayer(indx, playerName){
      if(confirm("Are you sure to remove the player: " + playerName)){
        let deleted = this.formData?.players?.[indx];
        if(deleted) {
          this.formData.players = this.formData.players.filter((player, index) => indx !== index);
          this.$emit('deletePlayer', deleted);
        }
        this.updateIndex++;
      }
    },
    editPlayer(player, index){
      this.errors = {};
      this.editForm = Object.assign({}, player);
      this.editIndex = index;
      if(!player.college_committed){
        this.collegeCommitted = 2;
      } else {
        this.collegeCommitted = 1;
      }
      this.$bvModal.show('edit-player');
    },
    removeInvite(email){
      this.inviteForm.invite_players = this.inviteForm.invite_players.filter(player => player != email);
    },
    validatePlayerInfo(){
      this.errors = {};
      if (!this.editForm.first_name) {
        this.errors.first_name = "First Name is required";
      }
      if (!this.editForm.last_name) {
        this.errors.last_name = "Last Name is required";
      }
      if (!this.editForm.email) {
        this.errors.email = "E-Mail is required";
      } else {
        if (!this.editForm.email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/) || !this.editForm.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
          this.errors.email = "Please enter a valid email";
        }
      }
      if (!this.editForm.retype_email) {
        this.errors.retype_email = "E-Mail is required";
      } else {
        if (!this.editForm.retype_email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/) || !this.editForm.retype_email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
          this.errors.retype_email = "Please enter a valid email";
        }
      }
      if(this.errors.email === undefined && this.errors.retype_email === undefined && this.editForm.email !== this.editForm.retype_email){
        this.errors.retype_email = "Email and Retype Email are not matched";
      }

      if(this.validateDOB(this.editForm.dob)) {
        this.errors.dob = this.validateDOB(this.editForm.dob);
      }

      if (!this.editForm.phone_1) {
        this.errors.phone_1 = "Phone is required";
      }
      if (!this.editForm.graduation_year) {
        this.errors.graduation_year = "High School Graduation Year is required";
      }
      if (!this.editForm.jersey_number) {
        this.errors.jersey_number = "Jersey Number is required";
      }
      if (!this.editForm.primary_position) {
        this.errors.primary_position = "Primary Position is required";
      }
      if (!this.editForm.throws) {
        this.errors.throws = "Throws is required";
      }
      if (!this.editForm.bats) {
        this.errors.bats = "Bats is required";
      }
      if(!this.editForm.address1){
        this.errors.address1 = "Address is required";
      }
      if(!this.editForm.city){
        this.errors.city = "City is required";
      }
      if(!this.editForm.country){
        this.errors.country = "country is required";
      }
      if(!this.editForm.state_code){
        this.errors.state_code = "State is required";
      }
      if (!this.editForm.zip) {
        this.errors.zip = "Zip Code is required";
      } else if (!this.editForm.zip.match(/^\d{5}(-\d{4})?$/) && this.editForm.country === 'USA') {
        this.errors.zip = "Zip Code Should Be 5 Number Values for USA.";
      } else if (this.editForm.zip.length < 5) {
        this.errors.zip = "Zip code should be 5 characters";
      }
      return Object.keys(this.errors).length;
    },
    updatePlayer(bvModalEvt){
      bvModalEvt.preventDefault();
      if(this.validatePlayerInfo()){
        return false;
      } else {
        if (this.collegeCommitted == 2) {
          this.editForm.college_committed = null;
          this.editForm.college_details = null;
        }
        let age = moment(this.formData?.clubData?.next_season_start_date).startOf("year").diff(this.editForm?.dob,"year", true);
        age = age > 0 ? Math.ceil((Math.floor(age * 1000) / 1000).toFixed(3)) : 0;
        let aged_out = parseInt(this.formData?.clubData?.ageDivision?.name) < age;
        this.editForm.age = age;
        this.editForm.aged_out = aged_out;

        this.$emit('updatePlayer', {data: this.editForm, index: this.editIndex});
        this.$nextTick(() => {
          this.updateIndex++;
          this.$bvModal.hide('edit-player');
        });
        if(this.formData?.players?.filter(player => player.aged_out === true).length){
          this.updateAgeOut = true;
        }
        else{
          this.updateAgeOut = false;
        }
      }
    },
    addPlayer(){
      this.errors = {};
      this.editForm = {
        new: true,
        graduation_year: null,
        jersey_number: null,
        primary_position: null,
        secondary_position: null
      };
      this.editIndex = this.formData.players.length;
      this.collegeCommitted = 2;
      this.$bvModal.show('edit-player');
    },
    onTagState(valid, invalid, duplicate) {
      this.validTags = valid
      this.invalidTags = invalid
      this.duplicateTags = duplicate
    },
    emailValidator(email) {
      return email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/) && email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    },
    setInvite(){
      this.$emit('playersInvite', this.inviteForm);
      this.$nextTick(() => {
        this.$bvModal.hide('modal-invite');
      });
    }
  },
  mounted() {
    const year = new Date().getFullYear();
    const yrs = year+20;
    const twoDigitYear = yrs.toString().substr(-2);
    const lengthOfYear = parseInt(20) + parseInt(twoDigitYear);
    this.yearArr = Array.from({length: lengthOfYear}, (value, index) => yrs - index);

    this.getCollegeCommittedsList();

    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      this.editIndex = null;
    });

    if(this.formData?.invitedPlayers) {
      this.inviteForm = this.formData.invitedPlayers;
    }
  },
  computed: {
    propFields: function() {
      return this.fields?.player_information?.popup;
    },
    playerFee: function() {
      let fee = this.formData?.clubData?.age_division === 5 ? process.env.VUE_APP_AFP_10U_FEE : this.formData?.membership?.player_price;
      return isNaN(fee) ? 0 : fee;
    },
    ageOut: function() {
     return !!this.formData?.players?.filter(player => player.aged_out === true).length;
    }
  }
}
</script>
<style>
.clear-fix{
  clear: both;
}
</style>